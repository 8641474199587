$(document).ready(function() {

  'use strict';

  // -----------
  // Mobile menu
  // -----------

  var handleMatchMedia = function (mediaQuery) {
    // ≤ 1023px
    if (mediaQuery.matches) {
      // crear el botó del menú
      // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
      // ocultar el menú
      $('.menu-wrapper').attr('hidden','').addClass('js-hidden');
      // clicar el botó
      $('.main-nav button').on('click', function() {
        // afegir classe al botó
        $(this).toggleClass('is-active');
        // afegir classe al body
        $('body').toggleClass('js-menu-open');
        // canviar atributs del botó i del menú
        if ($(this).attr('aria-expanded') == 'true') {
          $(this).attr('aria-expanded', 'false');
          $('.menu-wrapper').attr('hidden','').addClass('js-hidden');
        }
        else {
          $(this).attr('aria-expanded', 'true');
          $('.menu-wrapper').removeAttr('hidden').removeClass('js-hidden');
        }
      });
    }
    // ≥ 1024px
    else {
      // treure el botó
      // $('.main-nav button').remove();
      // mostrar el menú
      $('.menu-wrapper').removeAttr('hidden').removeClass('js-hidden');
    }
  },
  mq = window.matchMedia('only screen and (max-width: 1023px)');
  handleMatchMedia(mq);
  mq.addListener(handleMatchMedia);



  // --------
  // Submenús
  // --------

  // http://heydonworks.com/practical_aria_examples/#submenus
  $('.main-nav ul ul').prev('a')
    .attr('aria-haspopup', 'true')
    .append('<span aria-hidden="true"> &#x25be;</span>');

  var showSubmenu = function(dropdown) {
    dropdown.attr('aria-hidden', 'false');
  };

  var hideSubmenu = function(dropdown) {
    dropdown.attr('aria-hidden', 'true');
  };

  $('.with-dropdowns > li > a').on('focus', function(e) {
    hideSubmenu($('[aria-label="submenu"]'));
  });

  $('[aria-haspopup]').on('click', function(e) {
    var submenu = $(this).next();
    showSubmenu(submenu);
    //$(submenu).find('li:first-child a').focus();
    return false;
  });

  $('[aria-haspopup]').hover(function() {
    showSubmenu($(this).next());
    $(this).off('click');
  });

  $('[aria-haspopup]').parents('li').mouseleave(function() {
    hideSubmenu($(this).find('[aria-label="submenu"]'));
  });



  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $('html').addClass('svg');
  }


  //galeria
  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1], // Will preload 0 - before current, and 1 after the current image
      tCounter: '%curr% de %total%'
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.attr('title');
      }
    }
  });



  // --------------------------------------------------------------------------------------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // --------------------------------------------------------------------------------------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event){

      // strip the leading hash and declare
      // the content we're skipping to
      var skipTo="#"+this.href.split('#')[1];

      // Setting 'tabindex' to -1 takes an element out of normal
      // tab flow but allows it to be focused via javascript
      $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

          // when focus leaves this element,
          // remove the tabindex attribute
          $(this).removeAttr('tabindex');

      }).focus(); // focus on the content container
  });



  // -----------------
  // Canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName('img');
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
        if (imgs[i].src.match(endsWithDotSvg)) {
            imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
        }
    }
  }

  // $('.chzn-select').select2();




  // -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia('(min-width: 64em)').matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    // var headerHeight = 82; // fixed header height
    $(function() {
      $('a[name]').addClass('offset');
      $('a[href*="#"]:not([href="#"],[href="#menu"])').click(function(e) {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
              e.preventDefault();
              history.pushState({}, "", this.href);
              // if (window.matchMedia("(min-width: 64.0625em)").matches) {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top - headerHeight
              //       }, 1000);
              // } else {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top
              //       }, 1000);
              // }
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1000);
            return false;
          }
        }
      });
    });
  }





  // -------------
  // Sticky header
  // -------------

  var handleMatchMedia2 = function (mediaQuery) {
    // ≥ 1024px
    if (mediaQuery.matches) {
      var lastScrollTop = 115;
      if ($(!'body').hasClass('landing-postgraus')) {
        $(window).scroll(function(event){
            var st = $(this).scrollTop();
            if (st > lastScrollTop){
                $('.main-header-sub').addClass('sticky');
                $('body').addClass('sticky-header');
            } else {
                $('.main-header-sub').removeClass('sticky');
                $('body').removeClass('sticky-header');
            }
            // lastScrollTop = st;
        });
      }
    }
  },
  mq2 = window.matchMedia('only screen and (min-width: 1024px)');
  handleMatchMedia2(mq2);
  mq2.addListener(handleMatchMedia2);



  // ------------
  // Match height
  // ------------

  $('.box').matchHeight();
  $('.news-item').matchHeight();
  $('.agenda-item h2').matchHeight();
  $('.agenda-item').matchHeight();
  $('.info-box').matchHeight();
  $('.list-h-boxes li a').matchHeight();



  // --------------
  // Toggle idiomes
  // --------------

  $('.lang-toggle').click( function(event){
      event.stopPropagation();
      $(this).toggleClass('close');
      $(this).next().toggleClass('is-hidden');
  });
  $(document).click( function(){
      $('.lang-toggle').next().addClass('is-hidden');
  });



  // ---------------------
  // Toggle eines virtuals
  // ---------------------

  $('.eines-toggle').click( function(event){
      event.stopPropagation();
      $(this).toggleClass('close');
      $(this).next().toggleClass('is-hidden');
  });
  $(document).click( function(){
      $('.eines-toggle').next().addClass('is-hidden');
  });



  // -------------
  // Header search
  // -------------
  // (http://adrianroselli.com/2015/12/responsive-progressive-accessible-vanilla-search.html)

  // Get the search field by ID
  var eID = document.getElementById("s1");

  // Declare the event object and set it to null
  var eventObj = null;

  // Add the event listeners I want to track
  eID.addEventListener("focus", checkSearch, false);
  eID.addEventListener("blur", checkSearch, false);
  eID.addEventListener("keydown", checkSearch, false);
  // per ie11
  $("label[for='s1']").on('click', function(e){
    checkSearch(e);
    return false;
  });

  function checkSearch(eventObj) {
    try {
      // Get the event type from the object
      var eventType = eventObj.type
      // Map the tab key to a variable
      var TABKEY = 9;
      // Get the search input text value
      var eIDval = eID.value;
      // Get the parent element
      var eIDparent = eID.parentElement;
      // Set a class since we are on it
      eIDparent.className = "notempty";
      // Get the button
      var eIDbutton = eIDparent.getElementsByTagName('button')[0];
      // Set the button tabindex
      eIDbutton.setAttribute('tabindex', '0');
      // If the text value is not blank
      if (eIDval != '') {
        // Give it a class that will stay
        eIDparent.className = "notempty";
      }
      // If the text value is blank
      else {
        // Check if focus has left the input first
        if (eventType == 'blur') {
          // Set tabindex on button to remove from flow
          eIDbutton.setAttribute('tabindex', '-1');
          // Remove the class
          eIDparent.className = "";
        }
        // Check if user hit a key
        if (eventType == 'keydown') {
          // See if the user hit the tab key
          if (eventObj.keyCode == TABKEY) {
            // Set tabindex on button to remove from flow
            eIDbutton.setAttribute('tabindex', '-1');
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  $(document).click( function(){
      $('[role=search]').removeClass('notempty');
  });

  // -------
  // Galeria
  // -------
var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                //item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),
            showHideOpacity: true,
            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};

// execute above function
initPhotoSwipeFromDOM('.my-gallery');


  // -------
  // Sliders
  // -------

  $('.slider').slick({
      dots: true,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 8000
  });

  $('.slider-news').slick({
    // dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });



});